import React, { useContext } from 'react';
import { Table, Popconfirm, Input, Button } from 'antd';
import { DeleteOutlined, SearchOutlined, DeleteFilled, PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import { ADDITIONAL_REBATE_ACCLASSIF } from '../../pages/data/Data';

const OrderedProductsTable = ({
  data,
  deleteFromOrderHandler,
  columnKeys,
  title,
  increaseAmount,
  decreaseAmount,
  amount,
  achivedAdditionalDiscount,
  currentuser,
  adminRole,
}) => {
  const user = useContext(UserContext);

  const columns = [
    {
      key: 'index',
      title: 'BR.',
      render: (text, record, index) => <p>{index + 1}.</p>,
    },
    {
      key: 'code',
      title: 'Šifra',
      dataIndex: 'code',
    },
    {
      key: 'title',
      title: 'Artikal',
      dataIndex: 'title',
    },
    {
      title: 'Količina',
      render: (text, record, index) => {
        return (
          <div style={{ display: 'inline-flex', justifyContent: 'center', fontSize: '13px' }}>
            <Button
              onClick={() => increaseAmount(record.code)}
              icon={<PlusOutlined />}
              style={{ border: '0', width: '12px', marginRight: '7px', height: '5px' }}
            />
            <span style={{ fontSize: '15px', fontWeight: '700', marginTop: '-2px' }}>{record.amount}</span>
            <Button
              onClick={() => decreaseAmount(record.code)}
              icon={<MinusOutlined />}
              style={{ border: '0', width: '12px', marginLeft: '7px', height: '5px' }}
            />
          </div>
        );
      },
    },
    {
      key: 'wholesalePricePdv',
      title: 'VP Cena',
      dataIndex: 'wholesalePrice',
      render: (text, record, index) => <p>{record.wholesalePrice} rsd</p>,
    },
    {
      key: 'rebate',
      title: 'Rabat',
      render: (text, record, index) => {
        return (
          <div style={{display:'flex', alignItems:'center', justifyContent:'center', gap:'5px'}}>
            <p className={`rabatParagraph ${rabatCheck === 'Yes' ? 'redTextRabat' : ''}`}>{`${record.rebate}%`}</p>
            {achivedAdditionalDiscount && currentuser && currentuser.data && !currentuser.data.noBonus && !adminRole && !record.dontShowRebate && ADDITIONAL_REBATE_ACCLASSIF.includes(record?.acClassif?.trim()) ? (
              <span className='additionalDiscount'>+{achivedAdditionalDiscount}%</span>
            ) : null}
          </div>
        )
      }
    },
  ];

  columns.push({
    title: 'Ukupno',
    render: (text, record, index) => {
      let total_product_amount = (record.amount * (record.wholesalePrice - (record.wholesalePrice * record.rebate) / 100))
      if (achivedAdditionalDiscount && currentuser && currentuser.data && !currentuser.data.noBonus && !adminRole && !record.dontShowRebate && ADDITIONAL_REBATE_ACCLASSIF.includes(record?.acClassif?.trim())) {
        total_product_amount -= total_product_amount * (achivedAdditionalDiscount / 100)
      }
      return (
        <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '14px' }}>
          {total_product_amount.toFixed(2)} Rsd
        </div>
      )
    }
  });

  columns.push({
    title: '',
    render: (text, record) => (
      <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
        <div style={{ margin: '2px', padding: '4px' }} className='lock'>
          <Popconfirm
            disabled={record._id === user.data.id}
            placement='left'
            title={`Ovo će obrisati ${record.code} sa narudžbe`}
            onConfirm={() => {
              deleteFromOrderHandler(record.code);
            }}
            okText='U redu'
            cancelText='Poništi'
            cancelButtonProps={{ className: 'b2b-secondary' }}
            okButtonProps={{ className: 'b2b-primary' }}
          >
            <DeleteOutlined className='icon-unlock' title={`Obriši ${record.code}`} />
            <DeleteFilled className='icon-lock' title={`Obriši ${record.code}`} />
          </Popconfirm>
        </div>
      </div>
    ),
  });

  const rabatCheck = localStorage.getItem(`${user.data.id}_AdvancePaymentChecked`);

  return (
    <div key='order-products-table'>
      <Table
        size='middle'
        bordered
        className='orderProductTable'
        dataSource={data}
        columns={columns}
        rowKey='_id'
        pagination={{
          defaultPageSize: 10,
          position: 'bottom',
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '50', '100'],
          hideOnSinglePage: true,
        }}
      />
    </div>
  );
};

export default OrderedProductsTable;
