import React, { useState, useRef, useEffect } from 'react';
import TopBarMenuItem from './TopBarMenuItem';
import { useContext } from 'react';
import { UserContext } from '../../App';
import TopBarUser from './TopBarUser';
import { PiWarningCircleFill } from "react-icons/pi";
import axios from 'axios';
import { SERVER_URL } from '../../config';
import { notification } from 'antd';

const TopBarMenu = (props) => {
  const currentuser = useContext(UserContext);
  const adminRole = sessionStorage.getItem('adminRole');

  const total_debt = currentuser && currentuser.data && currentuser.data.dospelo ? currentuser.data.dospelo : 0;
  const otvoreno = currentuser && currentuser.data && currentuser.data.otvoreno ? currentuser.data.otvoreno : 0;

  // TODO: make span orange for some case
  const activeSpan = false

  const debtRef = useRef()
  const infoRef = useRef()
  const [debtInfoActive, setDebtInfoActive] = useState(false)

  const handleToggleDebtInfo = () => {
    setDebtInfoActive(prev => !prev)
  }

  const handleCloseDebtInfo = (event) => {
    if (debtRef && debtRef.current && !debtRef.current.contains(event.target) && infoRef && infoRef.current && !infoRef.current.contains(event.target)) {
      setDebtInfoActive(false)
    }
  }
  
  useEffect(() => {
    document.addEventListener('mousedown', handleCloseDebtInfo)

    return () => {
      document.removeEventListener('mousedown', handleCloseDebtInfo)
    }
  },[])

  const topBarMenuItems = [
    {
      icon: 'ri-settings-3-line',
      label: 'MOJI PODACI',
      link: `/company/view-user/${currentuser && currentuser?.data?.id}`,
    },
    {
      icon: 'ri-list-check',
      label: 'MOJE PORUDŽBINE',
      link: `/company/orders/${currentuser && currentuser?.data?.id}`,
    },
    // {
    //   icon: 'ri-file-list-3-line',
    //   label: 'REKLAMACIJE I POVRATI',
    //   link: `/company/reclamations/${currentuser && currentuser?.data?.id}`,
    // },
    // {
    //   icon: 'ri-bar-chart-box-line',
    //   label: 'KATALOZI I CENOVNICI',
    //   link: `/company/catalogs/${currentuser && currentuser?.data?.id}`,
    // },
    // {
    //   icon: 'ri-currency-line',
    //   label: 'FINANSIJE',
    //   link: `/company`,
    // },
  ];

  const handleSendCardRequest = async () => {
    try {
      const payload = {
        userId: currentuser.data.id
      }
      const response = await axios.post(`${SERVER_URL}/card-request`, payload, {
        withCredentials: false,
        headers: {
          authorization: `Bearer ${currentuser.data.token}`
        }
      })

      if (response.status === 200) {
        notification.success({
          message: 'Uspešno zatražena kartica.',
          placement:'bottomRight'
        })
      }
    } catch (error) {
      console.error(error)
      notification.error({
        message: 'Došlo je do greške prilikom zahteva.',
        placement:'bottomRight'
      })
    }
  }

  return (
    <div className='top-bar-menu-container'>
      {topBarMenuItems.map((item, index) => {
        return (
          <TopBarMenuItem key={index} topBarMenuItemProps={item} topBar={props.topBar} setTopBar={props.setTopBar} />
        );
      })}
      <div className='total_debt_container'>
        <div ref={infoRef} onClick={() => handleToggleDebtInfo()}>
          <PiWarningCircleFill style={{color:'red', fontSize:'23px', cursor:'pointer'}}/>
        </div>
        <p>DOSPELI DUG {total_debt ? <span style={{color:'red'}}>{total_debt.toLocaleString()} dinara</span> : ''}</p>
        
        <div ref={debtRef} className={`total_debt_info_container ${debtInfoActive ? 'active' : ''}`}>
          <div className='debt_title'>
            <span>DUGOVANJA</span>
          </div>
          
          <div className='table_grid'>
            <span className={activeSpan ? 'active' : ''}>Ukupan dug</span>
            <span className={activeSpan ? 'active' : ''}>{otvoreno.toLocaleString('en-US')}</span>
            <span className={activeSpan ? 'active' : ''}>Dospeli dug</span>
            <span className={activeSpan ? 'active' : ''}>{total_debt.toLocaleString('en-US')}</span>
            <span className={activeSpan ? 'active' : ''}>Dospeva</span>
            <span className={activeSpan ? 'active' : ''}>{(otvoreno - total_debt).toLocaleString('en-US')}</span>
          </div>

          <div className='card_container'>
            <button onClick={() => handleSendCardRequest()} className='btn' type='button'>Finansijska kartica</button>
          </div>
        </div>
      </div>
      {adminRole && (adminRole === 'admin' || adminRole === 'commercialist') && (
        <TopBarUser currentuser={currentuser} />
      )}
    </div>
  );
};

export default TopBarMenu;
