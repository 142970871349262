import React, { useState, useEffect } from 'react';
import { Table, Input, Button, notification, Drawer, Image, Divider, Tabs, Select, Modal } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import { ADDITIONAL_REBATE_ACCLASSIF } from '../../pages/data/Data';

const DataTable = ({
  modalNoArticle,
  noProductionModal,
  btnDisabled,
  replacementProduct,
  setNoProductionModal,
  replacementArticleModal,
  setReplacementArticleModal,
  modalArticle,
  HO_EOL,
  ACCLASSIF_ELA,
  data,
  mainCategory,
  rebate,
  // deleteHandler,
  // columnKeys,
  // title,
  // editPath,
  // viewPath,
  increaseAmount,
  decreaseAmount,
  amount,
  currentuser,
  show_1_onStock,
  userID,
  achivedAdditionalDiscount,
  adminRole
}) => {
  let searchInput;
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [modal, setModal] = useState({ visible: false, item: null, onStock: null, variations: [] });
  const [initialData, setInitialData] = useState({ item: null, onStock: null });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const filteredRebate =
    mainCategory &&
    rebate.supplierDiscount &&
    rebate.supplierDiscount.length &&
    rebate.supplierDiscount.find((item) => item?.supplierId?.title?.en === mainCategory);

  const rebateFiltered = '';

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Pretraga za + ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 220, marginBottom: 8, display: 'block' }}
          />
          <button
            className='b2b-primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 90, marginRight: 8 }}
          >
            Traži
          </button>
          <button className='b2b-secondary' onClick={() => handleReset(clearFilters)} style={{ width: 90 }}>
            Reset
          </button>
        </div>
      );
    },
    filterIcon: (filtered) => null,
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex)
        ? !dataIndex.includes('category')
          ? record[dataIndex[0]][dataIndex[1]]
          : record[dataIndex[0]][dataIndex[1]][dataIndex[2]]
        : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const rabatCheck = localStorage.getItem(`${userID}_AdvancePaymentChecked`);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const handleView = async (id) => {
    try {
      const resProduct = await Axios.get(`${SERVER_URL}/data/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      const resVar = await Axios.get(`${SERVER_URL}/variations/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      const resAvg = await Axios.get(`${SERVER_URL}/evaluations-calculate-average/${id}`);

      if (resProduct) {
        setModal({ 
          visible: true, 
          item: {
            ...resProduct.data.item,
            reviewsAvg: resAvg && resAvg.data && resAvg.data.avg ? resAvg.data.avg : null,
            numberOfReviews: resAvg && resAvg.data && resAvg.data.numberOfReviews ? resAvg.data.numberOfReviews : null,
          }, 
          onStock: resProduct.data.onStock, 
          variations: [] 
        });
        setInitialData({ item: resProduct.data.item, onStock: resProduct.data.onStock });

        if (resVar) {
          setModal({
            visible: true,
            item: {
              ...resProduct.data.item,
              reviewsAvg: resAvg && resAvg.data && resAvg.data.avg ? resAvg.data.avg : null,
              numberOfReviews: resAvg && resAvg.data && resAvg.data.numberOfReviews ? resAvg.data.numberOfReviews : null,
            },
            onStock: resProduct.data.onStock,
            variations: resVar.data,
          });
        }
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: 'Greška',
        description: 'Problem sa učitavanjem proizvoda',
        placement: 'bottomRight',
      });
    }
  };

  const handleViewReplacement = async () => {
    if (replacementProduct) {
      setModal({ visible: true, item: replacementProduct.item, onStock: replacementProduct.onStock, variations: [] });
    }
  };

  function shortenText(text, maxCharacters) {
    return text.length > maxCharacters ? text.slice(0, maxCharacters) + '...' : text;
  }

  const rowClassName = (record, index) => {
    const order = amount.find((item) => item.code === record.code);
    const noProduction = record.acClassif && HO_EOL.includes(record.acClassif.trim());
    if (order) {
      return 'ordered';
    }
    if (noProduction) {
      return 'noProduction';
    }
  };

  const maxCharacters = 60;

  const columns = [
    {
      key: 'code',
      title: 'Šifra',
      dataIndex: 'code',
      sorter: {
        compare: (a, b) => a.code.localeCompare(b.code),
        multiple: 2,
      },
      ...getColumnSearchProps('code'),
    },
    {
      key: ['title', 'en'],
      title: 'Artikal',
      dataIndex: ['title', 'en'],
      sorter: {
        compare: (a, b) => a.title.en.localeCompare(b.title.en),
        multiple: 2,
      },
      // value = dataIndex, record = ceo row
      render: (value, record) => {
        if (record.acClassif && ACCLASSIF_ELA.includes(record.acClassif.trim())) {
          return (
            <>
              {value} <span style={{ color: '#ff0000', fontWeight: '700' }}> - OUTLET</span>
            </>
          );
        } else return value;
      },
      ...getColumnSearchProps(['title', 'en']),
    },
    {
      key: 'minStock',
      title: 'Min. kol.',
      dataIndex: 'packageQuantity',
    },
    {
      key: 'onStock',
      title: 'Lager',
      dataIndex: 'onStock',
      render: (text, record) => (show_1_onStock && record.onStock === 0 ? 1 : record.onStock),
    },
    {
      key: 'wholesalePrice',
      title: 'VP Cena',
      render: (text, record, index) => <p>{record.wholesalePrice.toFixed(2)} rsd</p>,
    },
    {
      key: 'rabat',
      title: 'Rabat',
      render: (text, record, index) => {
        return (
          <div key={record._id} style={{display:'flex', alignItems:'center', justifyContent: 'center', gap:'5px'}}>
            <p className={`rabatParagraph ${rabatCheck === 'Yes' ? 'redTextRabat' : ''}`}>
              {record.dontShowRebate
                ? '0%'
                : record.acClassif && ACCLASSIF_ELA.includes(record.acClassif.trim())
                ? '0%'
                : rabatCheck === 'Yes'
                ? `${record.rebate + 5}%`
                : record.rebate
                ? `${record.rebate}%`
                : filteredRebate
                ? `${filteredRebate?.discount}%`
                : '0%'}
            </p>
            {achivedAdditionalDiscount && currentuser && currentuser.data && !currentuser.data.noBonus && !adminRole && !record.dontShowRebate && ADDITIONAL_REBATE_ACCLASSIF.includes(record?.acClassif?.trim()) ? (
              <span className='additionalDiscount'>+{achivedAdditionalDiscount}%</span>
            ) : null}
          </div>
        )
      }
    },
  ];

  columns.push(
    {
      title: 'Poručite',
      render: (text, record, index) => {
        return (
          <div style={{ display: 'inline-flex', justifyContent: 'center', fontSize: '13px' }}>
            <Button
              // disabled={!record?.stanje[0]?.stanje || (record?.stanje[0]?.stanje && amount[(currentPage - 1) * pageSize + index] === record?.stanje[0]?.stanje)}
              onMouseOver={() => modalArticle(record)}
              onClick={() => {
                if (record.acClassif && HO_EOL.includes(record.acClassif.trim())) {
                  modalNoArticle(record);
                  return;
                }
                increaseAmount(record.code, record.dontShowRebate ? undefined : record.rebate, record.supplierCode);
              }}
              icon={<PlusOutlined />}
              style={{ border: '0', width: '12px', marginRight: '7px', height: '5px' }}
            />
            <span style={{ fontSize: '15px', fontWeight: '700' }}>
              {amount.find((item) =>
                item.supplierCode ? item.supplierCode === record.supplierCode : item.code === record.code,
              )
                ? amount.find((item) =>
                    item.supplierCode ? item.supplierCode === record.supplierCode : item.code === record.code,
                  ).quantity
                : 0}
            </span>
            <Button
              disabled={
                !amount.find((item) => item.code === record.code)?.quantity ||
                amount.find((item) => item.code === record.code)?.quantity === 0
              }
              onMouseOver={() => modalArticle(record)}
              onClick={() => decreaseAmount(record.code, record.supplierCode)}
              icon={<MinusOutlined />}
              style={{ border: '0', width: '12px', marginLeft: '7px', height: '5px' }}
            />
          </div>
        );
      },
    },
    /*{
            title: 'Detaljnije',
      render: (text, record, index) => {
        return (
          <Button
            style={{ color: '#fb5700', backgroundColor: 'white', border: '1px solid' }}
            onClick={() => handleView(record._id)}
          >
            Pogledaj
          </Button>
          );
      },
    },
          */
  );

  let UP;
  let DKPL;
  let DKSRB;
  let DKISRB;
  if (modal && modal.item && modal.item.documents && modal.item.documents.length > 0) {
    UP = modal.item.documents.filter((item) => {
      return item?.originalname?.includes("UP");
    });
    DKPL = modal.item.documents.filter((item) => {
      return item?.originalname?.includes("DKPL");
    });
    DKSRB = modal.item.documents.filter((item) => {
      return item?.originalname?.includes("DKSRB");
    });
    DKISRB = modal.item.documents.filter((item) => {
      return item?.originalname?.includes("DKISRB");
    });
  }

  const tabItems = [
    {
      key: 'Deklaracija',
      label: 'DEKLARACIJA',
      children:
        modal.item && modal.item.declaration ? (
          <>
            <h5 style={{ fontSize: '12px', color: '#656565', fontWeight: '400' }}>
              <strong>Naziv proizvoda:</strong> {modal.item && modal.item.title && modal.item.title.en ? modal.item.title.en : modal?.item?.title}
            </h5>
            <h5 style={{ fontSize: '12px', color: '#656565', fontWeight: '400' }}>
              <strong>Proizvođač:</strong> {modal.item.declaration?.manufacturer?.en || 'Nema podataka'}
            </h5>
            <h5 style={{ fontSize: '12px', color: '#656565', fontWeight: '400' }}>
              <strong>Uvoznik:</strong> {modal.item.declaration?.importer?.en}
            </h5>
            <h5 style={{ fontSize: '12px', color: '#656565', fontWeight: '400' }}>
              <strong>Zemlja porekla:</strong> {modal.item.declaration?.countryOfOrigin?.en}
            </h5>
          </>
        ) : (
          <h5 style={{ fontSize: '12px', color: '656565', fontWeight: '400' }}>Nema deklaracije za ovaj proizvod!</h5>
        ),
    },
    {
      key: 'Dokumentacija',
      label: 'DOKUMENTACIJA',
      children:
        modal.item && modal.item.documents && modal.item.documents.length > 0 ? (
          <>
            {UP && UP.length > 0 ? UP.map(up => {
              let modifiedNameCustom;
              if (!up.modifiedname) {
                let nameOfDoc =
                  up?.originalname?.split(".");
                modifiedNameCustom =
                  nameOfDoc[0] +
                  " Uputstvo za upotrebu." +
                  nameOfDoc[1];
                modifiedNameCustom =
                  modifiedNameCustom?.split("_");
              }
              return (
                <div className='documentations'>
                  <img
                    src='/images/documentation.svg'
                    alt='documentation'
                    width={25}
                    height={25}
                  />
                  <a style={{fontWeight:'400', fontSize:'13px', color: '#1890ff'}} target='_blank' href={up.url}>
                    {up?.modifiedname
                      ? up.modifiedname
                      : modifiedNameCustom[1]}
                  </a>
                </div>
              )
            }) : ''}
            {DKPL && DKPL.length > 0 ? DKPL.map(dkpl => {
              let modifiedNameCustom;
              if (!dkpl.modifiedname) {
                let nameOfDoc =
                  dkpl?.originalname?.split(".");
                modifiedNameCustom =
                  nameOfDoc[0] +
                  " Deklaracija o usaglašenosti PL." +
                  nameOfDoc[1];
                modifiedNameCustom =
                  modifiedNameCustom?.split("_");
              }
              return (
                <div className='documentations'>
                  <img
                    src='/images/documentation.svg'
                    alt='documentation'
                    width={25}
                    height={25}
                  />
                  <a style={{fontWeight:'400', fontSize:'13px', color: '#1890ff'}} target='_blank' href={dkpl.url}>
                    {dkpl?.modifiedname
                      ? dkpl.modifiedname
                      : modifiedNameCustom[1]}
                  </a>
                </div>
              )
            }) : ''}
            {DKSRB && DKSRB.length > 0 ? DKSRB.map(dksrb => {
              let modifiedNameCustom;
              if (!dksrb.modifiedname) {
                let nameOfDoc =
                  dksrb?.originalname?.split(".");
                modifiedNameCustom =
                  nameOfDoc[0] +
                  " Deklaracija o usaglašenosti SRB." +
                  nameOfDoc[1];
                modifiedNameCustom =
                  modifiedNameCustom?.split("_");
              }
              return (
                <div className='documentations'>
                  <img
                    src='/images/documentation.svg'
                    alt='documentation'
                    width={25}
                    height={25}
                  />
                  <a style={{fontWeight:'400', fontSize:'13px', color: '#1890ff'}} target='_blank' href={dksrb.url}>
                    {dksrb?.modifiedname
                      ? dksrb.modifiedname
                      : modifiedNameCustom[1]}
                  </a>
                </div>
              )
            }) : ''}
            {DKISRB && DKISRB.length > 0 ? DKISRB.map(dkisrb => {
              let modifiedNameCustom;
              if (!dkisrb.modifiedname) {
                let nameOfDoc =
                  dkisrb?.originalname?.split(".");
                modifiedNameCustom =
                  nameOfDoc[0] +
                  " Sertifikat o pregledu tipa." +
                  nameOfDoc[1];
                modifiedNameCustom =
                  modifiedNameCustom?.split("_");
              }
              return (
                <div className='documentations'>
                  <img
                    src='/images/documentation.svg'
                    alt='documentation'
                    width={25}
                    height={25}
                  />
                  <a style={{fontWeight:'400', fontSize:'13px', color: '#1890ff'}} target='_blank' href={dkisrb.url}>
                    {dkisrb?.modifiedname
                      ? dkisrb.modifiedname
                      : modifiedNameCustom[1]}
                  </a>
                </div>
              )
            }) : ''}
          </>
        ) : (
          <h5 style={{ fontSize: '12px', color: '656565', fontWeight: '400' }}>Nema dokumentacije za ovaj proizvod</h5>
        ),
    },
    {
      key: 'Recenzije',
      label: 'RECENZIJE',
      children:
        modal && modal.item && modal.item.numberOfReviews && modal.item.numberOfReviews > 0 ? (
          <>
            <h5 style={{ fontSize: '12px', color: '656565', fontWeight: '400' }}>Prosečna ocena ovog proizvoda je: {modal.item.reviewsAvg}</h5>
          </>
        ) : (
          <>
            <h5 style={{ fontSize: '12px', color: '656565', fontWeight: '400' }}>Proizvod još uvek nije ocenjen!</h5>
          </>
        )
    },
  ];

  const tabItemsSec = [
    {
      key: 'Opis',
      label: 'OPIS',
      children:
        modal.item && modal.item.content && modal.item.content.en ? (
          <>
            <h5
              style={{
                fontSize: '13px',
                textAlign: 'justify',
                color: '#2e2d2d',
                fontVariant: 'tabular-nums',
                lineHeight: '1.5715',
                fontWeight: '400',
                fontFamily: 'Montserrat',
              }}
            >
              {modal.item.content.en.includes('Karakteristike') ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: modal.item.content.en.replace(/&nbsp;/g, ' ').split('Karakteristike')[0],
                  }}
                />
              ) : modal.item.content.en.includes('KARAKTERISTIKE') ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: modal.item.content.en.replace(/&nbsp;/g, ' ').split('KARAKTERISTIKE')[0],
                  }}
                />
              ) 
                : (
                <div dangerouslySetInnerHTML={{ __html: modal.item.content.en.replace(/&nbsp;/g, ' ') }} />
              )}
            </h5>
          </>
        ) : (
          <h5 style={{ fontSize: '12px', color: '#656565' }}>Nema opisa za ovaj proizvod!</h5>
        ),
    },
    {
      key: 'Karakteristike',
      label: 'KARAKTERISTIKE',
      children:
        modal.item &&
        modal.item.content &&
        modal.item.content.en &&
        modal.item.content.en.includes('Karakteristike') ? (
          <>
            <ul
              style={{
                listStyleType: 'disc',
                marginLeft: '20px',
                fontSize: '13px',
                color: '#2e2d2d',
              }}
            >
              {modal.item.content.en
                .replace(/<[^>]+>/g, '')
                .replace(/&nbsp;/g, ' ')
                .split('Karakteristike')[1]
                .trim()
                .split(';')
                .map((characteristic, index, characteristics) => (
                  <li key={index}>
                    {characteristic.trim().charAt(0).toUpperCase() + characteristic.trim().slice(1)}
                    {index !== characteristics.length && '.'}
                  </li>
                ))}
            </ul>
          </>
        ) : 
          modal.item &&
          modal.item.content &&
          modal.item.content.en &&
          modal.item.content.en.includes('KARAKTERISTIKE') ? (
          <>
            <ul
              style={{
                listStyleType: 'disc',
                marginLeft: '20px',
                fontSize: '13px',
                color: '#2e2d2d',
              }}
            >
              {modal.item.content.en
                .replace(/<[^>]+>/g, '')
                .replace(/&nbsp;/g, ' ')
                .split('KARAKTERISTIKE')[1]
                .trim()
                .split(';')
                .map((characteristic, index, characteristics) => (
                  <li key={index}>
                    {characteristic.trim().charAt(0).toUpperCase() + characteristic.trim().slice(1)}
                    {index !== characteristics.length && '.'}
                  </li>
                ))}
            </ul>
          </>
        ) : (
          <h5 style={{ fontSize: '12px', color: '#656565' }}>Nema karakteristika za ovaj proizvod!</h5>
        ),
    },
  ];

  const handleVariation = (var_id) => {
    if (var_id) {
      if (modal.variations && modal.variations.length > 0) {
        const foundVar = modal.variations.find((item) => item._id === var_id);

        if (foundVar) {
          setModal({
            ...modal,
            onStock: foundVar.onStock,
            item: {
              ...modal.item,
              wholesalePrice: foundVar.wholesalePrice,
              title: { en: foundVar.title.en },
              code: foundVar.code,
            },
          });
        }
      }
    } else {
      setModal({ ...modal, onStock: initialData.onStock, item: initialData.item });
    }
  };

  let minRebate = null;
  let maxRebate = null;

  if (data.length === 1) {
    minRebate = data[0].rebate || 0;
    maxRebate = minRebate;
  } else {
    data.forEach((product) => {
      const productRebate = product.rebate || 0;

      if (minRebate === null || productRebate < minRebate) {
        minRebate = productRebate;
      }

      if (maxRebate === null || productRebate > maxRebate) {
        maxRebate = productRebate;
      }
    });
  }

  let rebateValues = [];

  data.forEach((data) => {
    const rebate = data.rebate;
    if (rebate !== null && !rebateValues.includes(rebate)) {
      rebateValues.push(rebate);
    }
  });

  let rebateRange = '';

  if (rebateValues.length === 0) {
    rebateRange = '0%';
  } else if (rebateValues.length === 1) {
    rebateRange = `${rebateValues[0]}%`;
  } else {
    const nonNullRebates = rebateValues.filter((value) => value !== null);
    const minRebate = nonNullRebates.length > 0 ? Math.min(...nonNullRebates) : 0;
    const maxRebate = Math.max(...nonNullRebates);
    rebateRange = nonNullRebates.length > 0 ? `${minRebate}% - ${maxRebate}%` : '0%';
  }

  const [selectedProduct, setSelectedProduct /*, updateCategory*/] = useState(null);

  const handleProductClick = async (product) => {
    try {
      const response = await Axios.get(`${SERVER_URL}/data/${product._id}`);
      const productData = response.data;

      if (productData) {
        const updatedProduct = {
          ...product,
          featureImage: {
            url: productData.item.featureImage?.url || '',
          },
        };

        setSelectedProduct(updatedProduct);
        // updateCategory(productData.mainCategory);
      }
    } catch (error) {
      console.error('Error fetching product data:', error);
    }
  };

  return (
    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
      <div style={{ flex: '0 0 20%' }}>
        <div className='divRabat' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <span className='spanRabat' style={{ alignSelf: 'center' }}>
            Vaš rabat:
          </span>
        </div>
        <div className='rabatLine'></div>
        <div>
          <p class='categoryRabat'>
            {Array.isArray(mainCategory) ? mainCategory.join(', ') : mainCategory || 'SVE KATEGORIJE'}
          </p>
        </div>

        <div
          style={{
            display: 'inline-block',
            padding: '15px 5px',
            margin: '0px 40px 30px',
            backgroundColor: '#FB5700',
            color: 'white',
            width: rebateRange.includes('-') ? '105px' : '80px',
            textAlign: 'center',
            fontSize: '22px',
            fontWeight: '700',
            borderRadius: '5px',
          }}
        >
          {rebateRange !== 'null%' ? rebateRange : '0%'}
        </div>

        {selectedProduct && selectedProduct.featureImage && (
          <div style={{ position: 'relative', margin: '0 20px' }}>
            <img
              src={
                selectedProduct &&
                selectedProduct.featureImage &&
                selectedProduct.featureImage.url &&
                `https://api-shop.himtexcompany.com${selectedProduct.featureImage.url}`
              }
              style={{
                pointerEvents: 'none',
                cursor: 'default',
              }}
              alt={selectedProduct && selectedProduct.title && selectedProduct.title.en}
            />
            <span
              style={{
                cursor: 'pointer',
                position: 'absolute',
                top: '5px',
                right: '5px',
                width: '16px',
                height: '16px',
                background: '#f95e00',
                color: '#fff',
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '10px',
                fontWeight: 'bold',
                padding: '10px',
                textTransform: 'initial',
                zIndex: 1,
              }}
              onClick={() => handleView(selectedProduct._id)}
            >
              i
            </span>
          </div>
        )}
      </div>

      <div className='tableContainer' style={{ flex: '1 1 0' }}>
        <Table
          size='small'
          bordered
          dataSource={data}
          columns={columns.map((column) => ({
            ...column,
            render: (text, record, index) => (
              <span
                onClick={() => modalNoArticle(record)}
                onMouseOver={() => modalArticle(record)}
                onMouseEnter={() => handleProductClick(record)}
                style={{ cursor: 'pointer' }}
              >
                {column.render ? column.render(text, record, index) : text}
              </span>
            ),
          }))}
          rowKey='_id'
          key='_id'
          rowClassName={rowClassName}
          pagination={{
            defaultPageSize: 15,
            position: 'bottom',
            showSizeChanger: false,
            pageSizeOptions: ['10', '20', '50', '100'],
            hideOnSinglePage: true,
            onChange: (pageNum, pageSize) => {
              setCurrentPage(pageNum);
              setPageSize(pageSize);
            },
            current: currentPage,
          }}
          // pagination={false}
        />
      </div>

      {modal.item && (
        <Drawer placement='right' open={modal.visible} onClose={() => setModal({ visible: false })} width='30%'>
          <div>
            <Image
              src={
                modal.item.featureImage &&
                modal.item.featureImage.url &&
                `https://api-shop.himtexcompany.com${modal.item.featureImage.url}`
              }
            />
            <h2 style={{ fontSize: '24px', color: '#2e2d2d!important', marginTop: '10px', marginBottom: '0px' }}>
              {modal?.item?.title?.en}
            </h2>
            <h5 style={{ fontSize: '12px', color: '#868686', fontWeight: '300' }}>
              ŠIFRA: <span style={{ color: '#fb5700', fontWeight: '700' }}>{modal.item.code}</span>
            </h5>
            <h2 style={{ fontSize: '30px', color: '#f95e00', lineHeight: '24px', margin: '30px 0 0' }}>
              <strong>
                {modal.item.wholesalePrice
                  ? modal.item.wholesalePrice.toLocaleString('us-EN', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : '-'}{' '}
                RSD
              </strong>
            </h2>
            <p style={{ fontSize: '10px', color: '#868686', margin: '5px 0px 7px' }}>PDV NIJE URAČUNAT U CENU</p>
            <h5>
              {modal.onStock === 0 ? (
                <span style={{ color: 'red', textTransform: 'uppercase' }}>Nema na stanju</span>
              ) : (
                <span
                  style={{
                    fontSize: '14px',
                    fontWeight: '400',
                    marginRight: '5px',
                    color: '#0c3',
                    textTransform: 'uppercase',
                  }}
                >
                  Na lageru {modal.onStock} komad(a)
                </span>
              )}
            </h5>
            <Divider />

            <div style={{ display: 'grid', gridTemplateRows: 'auto auto', gap: '10px' }}>
              <div style={{ display: 'grid', gridTemplateColumns: '1fr auto', gap: '5px' }}>
                {modal.variations && modal.variations.length > 0 && (
                  <Select
                    style={{
                      gridArea: '1 / 1 / 2 / 3',
                    }}
                    allowClear
                    showSearch
                    optionFilterProp='children'
                    onChange={(value) => {
                      handleVariation(value);
                    }}
                    placeholder='VARIJACIJE...'
                    disabled={!modal.variations || modal.variations.length === 0}
                  >
                    {modal.variations.map((variation) => (
                      <Select.Option key={variation._id} value={variation._id} title={variation?.title?.en}>
                        {variation?.title?.en}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </div>

              <div style={{ display: 'grid', gridTemplateColumns: '1fr auto', gap: '5px' }}>
                <Button
                  style={{
                    color: 'white',
                    backgroundColor: '#fb5700',
                    width: windowWidth > 1718 ? '100.5%' : '101%',
                    textTransform: 'uppercase',
                    marginRight: '2px',
                    borderRadius: '4px 0 0 4px',
                  }}
                  onClick={() => increaseAmount(modal.item.code, filteredRebate, modal.item.supplierCode)}
                >
                  {amount.find((item) => item.code === modal.item.code) ? 'Dodaj još' : 'Dodaj u korpu'}
                </Button>

                <Button
                  style={{
                    cursor: 'text',
                    color: 'white',
                    borderRadius: '4px',
                    backgroundColor: '#545454',
                    padding: '0 10px',
                    gridArea: '1 / 2 / 2 / 3',
                    borderRadius: '0 4px 4px 0',
                  }}
                >
                  {amount.find((item) => item.code === modal.item.code)
                    ? amount.find((item) => item.code === modal.item.code).quantity
                    : 0}
                </Button>
              </div>
            </div>

            <br></br>
            {modal.item &&
              modal.item.pictogram &&
              modal.item.pictogram.map((pictogram, index) => (
                <div
                  key={index}
                  style={{ display: 'inline-block', marginRight: '8px', marginBottom: '8px', pointerEvents: 'none' }}
                >
                  <Image
                    src={
                      pictogram.image &&
                      pictogram.image.url &&
                      `https://api-shop.himtexcompany.com${pictogram.image.url}`
                    }
                    style={{ width: '50px', height: '50px' }}
                  />
                </div>
              ))}
            <br></br>
            {modal.item.sizeTableImage && (
              <h5
                style={{
                  marginBottom: '10px',
                  fontSize: '12px',
                  color: '#656565',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                }}
                onClick={openModal}
              >
                TABELA VELIČINA
                <span
                  style={{
                    marginLeft: '5px',
                    width: '16px',
                    height: '16px',
                    background: '#f95e00',
                    color: '#fff',
                    borderRadius: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '10px',
                    fontWeight: 'bold',
                    padding: '10px',
                  }}
                >
                  i
                </span>
              </h5>
            )}

            {isModalOpen && (
              <div
                className='modal'
                style={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.8)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: '9999',
                }}
              >
                <div
                  className='modal-content'
                  style={{
                    width: 'auto',
                    height: '90%',
                    backgroundColor: '#fff',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                  }}
                >
                  <button
                    className='close-button'
                    onClick={closeModal}
                    style={{
                      color: '#f95e00',
                      fontSize: '36px',
                      fontWeight: 700,
                      position: 'absolute',
                      top: '10px',
                      right: '10px',
                      width: '25px',
                      height: '25px',
                      background: 'transparent',
                      border: 'none',
                      cursor: 'pointer !important',
                      zIndex: '9999',
                    }}
                  >
                    X
                  </button>

                  <img
                    src='https://api-shop.himtexcompany.com/images/data/1667018661203_Tabela%20veli%C4%8Dina.webp'
                    alt='Tabela veličina'
                    style={{
                      maxWidth: '100%',
                      maxHeight: '100%',
                    }}
                  />
                </div>
              </div>
            )}

            <Divider />
            <Tabs defaultActiveKey='Deklaracija' items={tabItems}></Tabs>
            <Tabs defaultActiveKey='Opis' items={tabItemsSec}></Tabs>
          </div>
        </Drawer>
      )}
      <Modal open={noProductionModal} footer={false} onCancel={() => setNoProductionModal(false)}>
        <span className='outlet-desc' style={{ width: '300px' }}>
          Artikal koji se više ne proizvodi!
        </span>
      </Modal>
      <Modal open={replacementArticleModal} footer={false} onCancel={() => setReplacementArticleModal(false)}>
        <span className='outlet-desc' style={{ width: '300px' }}>
          Artikal koji se više ne proizvodi!
        </span>
        <span className='outlet-line' style={{ margin: '20px 0px' }}></span>
        <button
          disabled={btnDisabled}
          className='replacementArticleButton'
          onClick={() => {
            setReplacementArticleModal(false);
            handleViewReplacement();
          }}
        >
          Pogledaj zamenski artikal
        </button>
      </Modal>
    </div>
  );
};

export default DataTable;
