import React, { useContext, useEffect, useState } from 'react';
import { IoIosArrowForward } from "react-icons/io";
import axios from 'axios'
import {SERVER_URL} from '../config'
import { UserContext } from '../App';
import { CgNotes } from "react-icons/cg";
import { notification } from 'antd';

const Home = () => {
  const currentuser = useContext(UserContext)

  const [sendRequest, setSendRequest] = useState(false)
  const [banners, setBanners] = useState([])
  const [catalogs, setCatalogs] = useState([])

  const fetchBanners = async () => {
    try {
      const response = await axios.get(`${SERVER_URL}/dashboard-home-front-banners`, {
        withCredentials: false,
        headers: {
          authorization: `Bearer ${currentuser.data.token}`
        }
      })

      if (response.status === 200){
        setBanners(response.data)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const fetchCatalogs = async () => {
    try {
      const response = await axios.get(`${SERVER_URL}/dashboard-home-front-catalogs`, {
        withCredentials: false,
        headers: {
          authorization: `Bearer ${currentuser.data.token}`
        }
      })

      if (response.status === 200){
        setCatalogs(response.data)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchBanners()
    fetchCatalogs()
  },[])

  const handleRequestCard = async () => {
    setSendRequest(true)

    try {
      const user = currentuser.data
      const {token} = user

      let companyName = ''

      if (user.legalType === 'corporate'){
        companyName = user.companyName
      }else if(user.legalType === 'private'){
        companyName = `${user.firstName} ${user.lastName}`
      }
      
      const payload = {
        companyName: companyName
      }
      const response = await axios.post(`${SERVER_URL}/konto-card-request`, payload, {
        withCredentials: false,
        headers: {
          authorization: `Bearer ${token}`
        },
      })

      if(response.status === 201){
        notification.success({
          message: response.data.message,
          placement:'bottomRight'
        })

        setSendRequest(false)
      }
    } catch (error) {
      console.error(error)
      
      notification.error({
        message: 'Greška pri slanju zahteva.',
        placement:'bottomRight'
      })

      setSendRequest(false)
    }
  }

  return (
    <div className='home_container'>
      <div className='banners_with_debt_value_container'>
        <div className='banners_container'>
          {banners && banners.length > 0 && banners.map((item, index) => {
            return (
              <a key={index} href={`/company/banner/${item._id}`} className='banner'>
                <div className='banner_image'>
                  <img className='img' src={item.image ? item.image : '/B2B-login.webp'}/>
                </div>

                <div className='banner_title'>
                  <div>
                    <span className='title'>{item.title}</span>
                  </div>

                  <div className='arrow_icon'>
                    <IoIosArrowForward className='icon'/>
                  </div>
                </div>
              </a>
            )
          })}
        </div>

        {/* <div className='debt_container'>
          <div className='total_debt_container'>
            <div className='total_debt_header'>
              <CgNotes style={{fontSize: '20px'}}/>

              <div>
                <span className='total_debt_title'>Vase ukupo zaduzenje</span>
              </div>
            </div>

            <div className='total_debt_value'>
              <span>USKORO</span>
            </div>
          </div>

          <div className='debt_out_of_value_container'>
            <div className='debt_out_of_value_header'>
              <CgNotes style={{fontSize: '20px'}}/>

              <div>
                <span className='debt_out_of_value_title'>zaduzenje van valute</span>
              </div>
            </div>

            <div className='debt_out_of_value_price'>
              <span>USKORO</span>
            </div>
          </div>

          <div className='debt_message_container'>
            <p>
              Molimo Vas da svoja dugovanja van valute izmirite što je to ranije moguće, kako bi nam olakšali dalji tok poslovanja.
            </p>
          </div>

          <div className='konto_container'>
            <div className='konto_container_header'>
              <CgNotes style={{fontSize: '20px'}}/>

              <div>
                <span className='konto_container_title'>konto kartica</span>
              </div>
            </div>
          </div>

          <div className='debt_request_container'>
            <button disabled={true} className='request_btn' type='button' onClick={() => handleRequestCard()}>
              {sendRequest ? 'Slanje...' : 'pošalji zahtev'}
            </button>
          </div>
        </div> */}
      </div>

      <div style={{display: 'flex', gap: '50px'}}>
        <div className='catalogs_container'>
          {catalogs && catalogs.length > 0 && catalogs.map((item, index) => {
            return (
              <a href={item.link} key={index} className='catalog' target='_blank'>
                <div className='catalog_image'>
                  <img className='img' src={item.image}/>
                </div>

                <div className='catalog_title'>
                  <span className='title'>{item.title}</span>
                </div>
              </a>
            )
          })}
        </div>

        <div style={{width:'200px', display: 'flex', flexShrink: '0'}}/>
      </div>
    </div>
  )
};

export default Home;
